<script setup lang="ts">
export interface Tag {
  id: string
  name: string
}

const emit = defineEmits(['update:active'])

const props = withDefaults(
  defineProps<{
    tags: Tag[]
    active: string[]
    all?: boolean
    multiple?: boolean
  }>(),
  {
    all: false,
    multiple: false,
  },
)

const active = computed({
  get: () => props.active,
  set: (value) => emit('update:active', value),
})

const allActive = computed(() => props.all && props.tags.length === active.value.length)

const onClick = (id: string) => {
  const isCurrentlyActive = active.value.includes(id)
  const isSingleActive = active.value.length === 1 && isCurrentlyActive

  if (props.all) {
    if (isSingleActive) {
      // If 'all' is true and the only active tag is clicked, activate all tags
      return (active.value = props.tags.map((tag) => tag.id))
    }
  } else {
    if (isSingleActive) {
      // If not 'all' and the only active tag is clicked, deactivate it
      return (active.value = [])
    }
  }

  if (props.multiple) {
    // Handle multiple selection
    return (active.value = isCurrentlyActive
      ? active.value.filter((activeId) => activeId !== id)
      : [...active.value, id])
  }

  // Handle single selection
  active.value = [id]
}

const onClickAll = () => {
  active.value = props.tags.map((tag) => tag.id)
}
</script>

<template>
  <ul v-animate="{ cluster: true }" class="tags cul">
    <li v-if="props.all" v-animate="{ animation: 'vertical-reveal' }" class="tag">
      <Tag name="all" :active="allActive" @click="onClickAll" />
    </li>
    <li v-for="(tag, index) in props.tags" :key="`${index}-${tag?.id}`" v-animate="{ animation: 'vertical-reveal' }">
      <Tag v-bind="tag" :active="!allActive && active.includes(tag.id)" @click="onClick(tag.id)" />
    </li>
  </ul>
</template>
<style scoped lang="scss">
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
